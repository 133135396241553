import React from "react"
import { Link, graphql } from "gatsby"
import get from "lodash.get"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/page-hero"
import Grid from "../components/grid"
import Breadcrumbs from "../components/breadcrumbs"
import Listing from "../components/listing"
import Pagination from "../components/pagination"

export const pageQuery = graphql`
  query ArticleList($limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    prismicNews {
      data {
        name
        title
        description {
          text
          richText
        }
        image {
          alt
          copyright
          gatsbyImageData(layout: CONSTRAINED, width: 504, height: 280)
        }
      }
    }

    allPrismicPost(
      sort: { fields: data___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      pageInfo {
        currentPage
        pageCount
      }
      nodes {
        id
        uid
        type
        data {
          image {
            alt
            copyright
            gatsbyImageData(layout: CONSTRAINED, width: 328, height: 210)
          }
          category {
            document {
              ... on PrismicCategory {
                id
                uid
                type
                data {
                  name
                }
              }
            }
          }
          title
          date
          body {
            ... on PrismicPostDataBodyText {
              id
              slice_type
              slice_label
              primary {
                text {
                  text
                  richText
                }
              }
            }
          }
        }
      }
    }
  }
`

export default function News({ location, data }) {
  const page = get(data, `prismicNews.data`)
  const posts = get(data, `allPrismicPost.nodes`)
  const pageInfo = get(data, `allPrismicPost.pageInfo`)

  return (
    <Layout location={location}>
      <SEO
        title={`${page.name} | Page ${pageInfo.currentPage}`}
        description={page.description.text}
      />

      <Grid>
        <Hero
          title={page.title}
          subtitle={page.description}
          image={page.image}
        />
        <Breadcrumbs>
          <Link to="/">Home</Link>
          <span>{page.name}</span>
        </Breadcrumbs>
        <Listing nodes={posts} />
        <Pagination path="/news" pageInfo={pageInfo} />
      </Grid>
    </Layout>
  )
}
